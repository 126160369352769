@media screen and (max-width: 767px){
    .container-fluid, .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

}


@media screen and (min-width: 600px) and (max-width: 768px){
}