@media screen and (max-width: 1022px){
    .main-nav .topNavBar ul li a.mobile-req{
        display: block;
        padding: 3px 10px;
    }
    .hamburger-wrap{visibility: visible; pointer-events: visible; position: relative;}
    nav#top-nav .mobile-menu-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        -moz-transition: all .4s ease-in-out;
        -ms-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        position: fixed;
        flex-direction: column;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
       
}
    nav#top-nav .mobile-menu-wrap.show {
        transform: translate(0, 0);
        height: 100%;
        top: 0;
        left: 0;
    }
    nav#top-nav .mobile-menu-wrap .nav-item .nav-link {
        font-size: 24px;
        margin-bottom: 10px;
        &::before{display: none;}
    }

    nav#top-nav{padding: 0px;}
    nav#top-nav.fix-nav {
        padding: 0px;
    }
    .logo img{max-width: 110px;}
    .fix-nav .logo img{max-width: 100px;}
}